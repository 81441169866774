
import Card from '@/components/layout/Card.vue'
import Badge from '@/components/status/Badge.vue'
import BusinessBranchCard from 'tradingmate-modules/src/models/api/businesses/BusinessBranchCard'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { BusinessBranchStatus } from '../../../../../tradingmate-modules/src/models/api/businesses'
import { CategoryModel } from '../../../../../tradingmate-modules/src/models/api/categorisation'

@Component({
  components: {
    Card,
    Badge
  }
})

export default class MemberInactiveCard extends Vue {
  @Prop({ required: true })
  private readonly member!: BusinessBranchCard

  @Prop({ default: null })
  private readonly preferredCategoryIds!: string[] | null

  get thumbnail (): string {
    const thumb = '/assets/images/brand/placeholder.jpg'
    return thumb
  }

  private inactive = BusinessBranchStatus.Inactive

  preferredCategory (): CategoryModel | null {
    const intersection = this.member.Categories.filter(value => this.preferredCategoryIds?.includes(value.CategoryId))
    if (intersection.length) {
      return intersection[0]
    } else if (this.member.Categories[0]) {
      return this.member.Categories[0]
    } else {
      return null
    }
  }

  activateThisBusiness (): void {
    //
  }
}

