import { render, staticRenderFns } from "./MemberInactiveCard.vue?vue&type=template&id=7089eab4&scoped=true"
import script from "./MemberInactiveCard.vue?vue&type=script&lang=ts"
export * from "./MemberInactiveCard.vue?vue&type=script&lang=ts"
import style0 from "./MemberInactiveCard.vue?vue&type=style&index=0&id=7089eab4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7089eab4",
  null
  
)

export default component.exports